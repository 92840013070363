import React, { useState, useEffect } from "react"
import { Link, navigate } from 'gatsby'

import Breadcrumb from '../components/Breadcrumb/Breadcrumb'
import PageHeader from '../components/Header/PageHeader'

import useAuth from '../hooks/useAuth'

const ResetPasswordPage = ( { location }) => {

	const { changePassword } = useAuth();

	const [ password, setPassword ] = useState('');
	const [ confirmPassword, setConfirmPassword ] = useState('');
	const [ passwordMatchError, setPasswordMatchError ] = useState(false);
	const [ token, setToken ] = useState();
	const [ error, setError ] = useState( false );
	const [ showForm, setShowForm ] = useState( true )

	useEffect(() => {
		if ( location.search ){
			let searchQuery = location.search.replace(/[=?]/g, " ")
			//sorting out the search query
			searchQuery = searchQuery.split(" ").splice(1)
			if( searchQuery[0] ==='code' ){
				setToken( searchQuery[1] )
			}
		}
	}, [ location.search ])

	const handleSubmit = async ( event ) => {
		event.preventDefault()

		if ( password !== confirmPassword ) {
			setPasswordMatchError( true )
			return;
		} else {
			setPasswordMatchError( false )
		}

		const data = {
			code: token,
			password,
			passwordConfirmation: confirmPassword
		}

		try {
			await changePassword( data );
			navigate( '/account' )
			setShowForm( false )
		} catch( e ) {
			console.log("Error occurred during authentication")
			setError( true )
		}
	}

	return (
		<>
			<Breadcrumb pathname={ location.pathname } />
			<div className="wrapper">
				<PageHeader title="Reset Password" />
				<div className="login">
					<div className="login__container">

						{ showForm ? (
							<form  className="login__form form" onSubmit={ handleSubmit }>

								<div className="form__section-heading">
									Reset Your Password
								</div>

								<div className="form__input-container">
									<label className="form__label" htmlFor="password">New Password</label>
									<input className="form__input" type="password" name="password" id="password" value={ password } onChange={ e => { setPassword( e.target.value ) } } required/>
								</div>
								<div className={`form__input-container ${ passwordMatchError ? 'error' : '' }`}>
									<label className="form__label" htmlFor="confirmPassword">Confirm New Password</label>
									<input className="form__input" type="password" name="confirmPassword" id="confirmPassword" value={ confirmPassword } onChange={ e => { setConfirmPassword( e.target.value ) } } required/>
									<span className="form__input-error">Password confirmation does not match password!</span>
								</div>

								{ error &&
									<div className="form__error-container">
										<span className="form__error-message">Sorry, this link has expired.</span>
										<span className="form__error-message"><Link to="/forgotten-password">Resend email</Link></span>
									</div>
								}

								<button type="submit" className="form__submit">
									Change Password
								</button>
							</form>
						) : (
							<>
								<h2> Your Password has been reset.</h2>
							</>
						) }
					</div>
				</div>
			</div>
		</>
	)
}

export default ResetPasswordPage;